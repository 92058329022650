'use client';
import { useEffect, useRef, useState } from 'react';
import useIntersectionObserver from '@util/hooks/useIntersectionObserver';
import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { usePathname } from 'next/navigation';

interface CarouselProps {
  items: React.ReactNode[];
  incrementBy?: number;
  showPaginator?: boolean;
  loading?: boolean;
  emptyMessage?: React.ReactNode | null;
}

const CarouselComponent = ({
  items,
  incrementBy,
  showPaginator,
  loading,
  emptyMessage = null,
}: CarouselProps) => {
  const INCREMENT_BY = incrementBy || 4;
  const FLEX_GAP = 16;
  const [currentIndex, setCurrent] = useState(0);
  const carouselfRef = useRef<HTMLDivElement>(null);
  const frontRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const pathname = usePathname();

  const frontEntry = useIntersectionObserver(frontRef, {});
  const endEntry = useIntersectionObserver(endRef, {});

  const handleLeftArrowClick = () => {
    setCurrent((prev) => Math.max(prev - INCREMENT_BY, 0));
  };
  const handleRightArrowClick = () =>
    setCurrent((prev) => Math.min(prev + INCREMENT_BY, items.length));

  useEffect(() => {
    if (carouselfRef.current && cardRef.current) {
      const cardWidth = currentIndex * cardRef.current.offsetWidth;
      const cardSpacing = currentIndex * FLEX_GAP;
      const offset = cardWidth + cardSpacing;
      carouselfRef.current.scrollLeft = offset;
    }
  }, [currentIndex]);

  const isHomepage = pathname === '/';

  return (
    <div className="w-full min-w-[0rem] ">
      <div className="relative w-full">
        {items && items.length > 0 ? (
          <>
            {/* left arrow */}
            {!frontEntry?.isIntersecting && (
              <div className="absolute -left-16 top-0 z-20 hidden h-full items-center sm:flex">
                {/* <Arrow variant="left" onClick={() => handleLeftArrowClick()} /> */}
                <ChevronLeftIcon
                  className="h-14 w-14 cursor-pointer text-brand-secondary"
                  onClick={() => handleLeftArrowClick()}
                />
              </div>
            )}

            <div
              className="no-scrollbar w-full overflow-x-scroll scroll-smooth px-4 pb-[1rem] sm:px-0 lg:overflow-x-hidden"
              ref={carouselfRef}
            >
              <div
                className={`mobile-zoom-product-card-container flex w-min ${
                  isHomepage ? 'gap-[1rem]' : 'gap-[2rem]'
                } p-4`}
              >
                {items.map((item, i) => {
                  // append extra div to front of first item for frontRef
                  if (i === 0) {
                    return (
                      <div key={i} className="flex">
                        <div ref={frontRef} />
                        <div ref={i === 0 ? cardRef : null}>{item}</div>
                      </div>
                    );
                  }
                  // append extra div to end of last item for endRef
                  if (i === items.length - 1) {
                    return (
                      <div key={i} className="flex">
                        <div ref={i === 0 ? cardRef : null}>{item}</div>
                        <div ref={endRef} />
                      </div>
                    );
                  }
                  return <div key={i}>{item}</div>;
                })}
              </div>
            </div>

            {/* right arrow */}
            {!endEntry?.isIntersecting && (
              <div className="absolute -right-16 top-0 z-10 hidden h-full items-center sm:flex">
                {/* <Arrow
                  variant="right"
                  onClick={() => handleRightArrowClick()}
                /> */}

                <ChevronRightIcon
                  className="h-14 w-14 cursor-pointer text-brand-secondary"
                  onClick={() => handleRightArrowClick()}
                />
              </div>
            )}
          </>
        ) : loading ? (
          <div className="flex gap-[1.6rem]">
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </div>
        ) : (
          emptyMessage
        )}

        {showPaginator && (
          <div className="mt-[1.6rem] flex justify-center gap-[0.8rem] md:hidden">
            {items.map((_, i) => {
              const isCurrent = i === currentIndex;
              return (
                <div
                  key={i}
                  className={`h-[0.8rem] w-[0.8rem]  rounded-full transition-colors sm:h-[0.8rem] sm:w-[0.8rem] ${
                    isCurrent ? 'bg-brand-secondary' : 'bg-gray-300'
                  }`}
                  onClick={() => {
                    setCurrent(i);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CarouselComponent;
