import { useQuery } from '@tanstack/react-query';
import {
  getPartsForYourBike,
  getPartsForYourBikeFromRecommend,
} from '@util/firestore/products';
import { useAuth } from 'context/AuthContext';
import { Bike } from 'models/shared';

const usePartsForYourBike = (bike?: Bike) => {
  const { userDoc } = useAuth();
  const { data, isLoading } = useQuery({
    queryKey: [bike],
    queryFn: async () => {
      // if (!!bike) {
      //   return getPartsForYourBike(bike, userDoc!.uid);
      // }
      if (!!bike) {
        const res = await getPartsForYourBikeFromRecommend(bike);
        if (!res.results.length) {
          return getPartsForYourBike(bike, userDoc!.uid);
        }
        return res;
      }
    },
    enabled: !!bike && !!userDoc?.uid,
  });

  const partsForYourBike = !!data ? data?.results : [];
  return {
    partsForYourBike,
    isLoading,
  };
};

export default usePartsForYourBike;
