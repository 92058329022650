import MXProductCard from '@c/cards/MXProductCard';
import ProductVideoCard from '@c/cards/ProductVideoCard';
import { ProductDocument } from '@models/product';
import { OrderItemDocument } from 'models/order';

export const renderProductCards = (
  productItems?: ProductDocument[],
  props?: any,
  attribution?: OrderItemDocument['attribution']
) =>
  productItems?.map((product) => (
    <MXProductCard
      product={product}
      key={product.id}
      attribution={attribution}
      {...props}
    />
  )) ?? [];

export const renderVideoCards = (
  productItems?: ProductDocument[],
  props?: any,
  attribution?: OrderItemDocument['attribution']
) =>
  productItems?.map((product) => (
    <ProductVideoCard
      product={product}
      key={product.id}
      attribution={attribution}
      {...props}
    />
  )) ?? [];
