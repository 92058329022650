// import { isMobile } from '@util/index';
import { isMobile } from '@util/index';
import { BannerDocument } from 'models/banner';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Hammer from 'react-hammerjs';

const HomepageHeader = ({ banners }: { banners: BannerDocument[] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (isMobile() || !banners?.length) return;
    const interval = setInterval(() => {
      const container = document.getElementById('container');
      if (activeIndex < banners.length - 1) {
        container?.scrollTo({
          left: (activeIndex + 1) * window.innerWidth,
          behavior: 'smooth',
        });
        setActiveIndex(activeIndex + 1);
      } else {
        container?.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        setActiveIndex(0);
      }
    }, 5000);
    return () => clearInterval(interval);
  });

  const allBanners = banners
    .sort((a, b) => a.index! - b.index!)
    .filter((banner) => !!banner.index);

  return (
    <div className="relative flex flex-col">
      <Hammer
        onSwipe={(e) => {
          const container = document.getElementById('container');
          if (e.direction === 4) {
            if (activeIndex > 0) {
              container?.scrollTo({
                left: (activeIndex - 1) * window.innerWidth,
                behavior: 'smooth',
              });
              setActiveIndex(activeIndex - 1);
            }
          }
          if (e.direction === 2) {
            if (activeIndex < banners.length - 1) {
              container?.scrollTo({
                left: (activeIndex + 1) * window.innerWidth,
                behavior: 'smooth',
              });
              setActiveIndex(activeIndex + 1);
            }
          }
        }}
      >
        <div id="container" className="flex max-w-[100vw] overflow-hidden">
          {allBanners.map((banner) => (
            <HomePageHero key={banner.id} {...banner} />
          ))}
        </div>
      </Hammer>

      <div className="absolute bottom-[4rem] z-10 flex w-full justify-center gap-[0.8rem] sm:bottom-[1.6rem] sm:right-0">
        {allBanners.length / 2 > 1 &&
          allBanners.slice(0, allBanners.length / 2).map((_, index) => (
            <button
              key={index}
              aria-label="scroll to category"
              aria-hidden
              tabIndex={-1}
              className={`${
                activeIndex === index ? 'bg-white' : 'bg-gray-400'
              } h-[0.8rem] w-[3.8rem] rounded-full transition-colors hover:bg-gray-500 sm:h-[0.6rem] sm:rounded-none`}
              onClick={() => {
                const container = document.getElementById('container');
                container?.scrollTo({
                  left: index * window.innerWidth,
                  behavior: 'smooth',
                });
                setActiveIndex(index);
              }}
            />
          ))}
      </div>
    </div>
  );
};

const HomePageHero = (banner: BannerDocument) => {
  const Component = banner.route ? Link : 'div';

  return (
    <Component
      href={banner.web_mobile_route ?? banner.route ?? '#'}
      aria-label={banner.alt}
      className={`w-[100vw] shrink-0 flex-col gap-[1.2rem] sm:h-[70rem] sm:flex-row lg:h-[50rem] ${
        banner.app ? 'flex sm:hidden' : 'hidden sm:flex'
      }`}
    >
      {banner.src && (
        <div
          className={`relative hidden h-[20rem] w-full sm:block sm:h-full`}
          key={'desktop'}
        >
          <Image
            src={banner.src + ''}
            alt={banner.alt}
            fill
            className="z-10 mx-auto h-[50rem] w-[144rem] object-cover"
            loading={banner.index === 0 ? 'eager' : 'lazy'}
          />
          <Image
            src={banner.src}
            alt={banner.alt}
            fill
            className="w-[100vw] object-cover blur"
            loading={'lazy'}
          />
        </div>
      )}

      {banner.app && (
        <div className="flex p-8 sm:hidden sm:h-full">
          <div className={`relative h-[30rem] w-full`} key={'mobile'}>
            <Image
              src={banner.src + '?width=800&t=800&optimizer=image'}
              alt={banner.alt}
              fill
              className="rounded-2xl object-cover object-top"
              loading={banner.index === 1 ? 'eager' : 'lazy'}
              priority={banner.index === 1}
            />
          </div>
        </div>
      )}
    </Component>
  );
};

export default HomepageHeader;
