import { CheckCircleIcon } from '@c/icons';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { ProductDocument } from '@models/product';
import { addFollowers, removeFollower } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { PublicUserDocument } from 'models/user';

export const Star = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 14.808L15.15 17.9163L13.7833 12.058L18.3333 8.11634L12.3416 7.60801L9.99996 2.08301L7.65829 7.60801L1.66663 8.11634L6.21663 12.058L4.84996 17.9163L9.99996 14.808Z"
      fill="#C41719"
    />
  </svg>
);

const Rating = ({
  value,
  numberOfReviews,
}: {
  value: number;
  numberOfReviews: number;
}) => (
  <div className="flex items-center gap-[0.4rem] whitespace-nowrap">
    <Star />
    <span className="text-[1.6rem] font-semibold">{value.toFixed(2)}</span>
    <span className="hidden text-[1.6rem] text-[#333333] lg:block">
      ({numberOfReviews ?? 0} reviews)
    </span>
  </div>
);

const SellerInfo = ({ seller }: { seller: PublicUserDocument }) => (
  <div className="flex w-full flex-col flex-wrap gap-[0.4rem]">
    <span className="font-semibold">{seller.username}</span>
    <Rating value={seller.rating} numberOfReviews={seller.reviews} />
  </div>
);

const SellerImage = ({ src, alt }: { src: string; alt: string }) => (
  <div className="relative min-h-[6.4rem] min-w-[6.4rem] rounded-full">
    <SafeImage src={src} alt={alt} fill className="rounded-full" />
  </div>
);

const SellerCardHeader = ({ seller }: { seller: PublicUserDocument }) => {
  const { userDoc } = useAuth();
  const queryClient = useQueryClient();
  const router = useRouter();
  return (
    <div className="flex w-full items-center gap-[3.2rem] lg:justify-between lg:gap-[0.8rem]">
      <Link
        href={`/profile/${seller.username_slug}`}
        className="flex gap-[0.8rem]"
      >
        <SellerImage src={seller.photo} alt={seller.username} />
        <SellerInfo seller={seller} />
      </Link>
      {userDoc?.following.includes(seller.uid) ? (
        <Button
          text="Following"
          height="small"
          type="green"
          leadingIcon={<CheckCircleIcon />}
          width="small"
          onClick={async () => {
            await removeFollower(userDoc?.uid, seller.uid);
            queryClient.invalidateQueries({
              queryKey: ['authUser'],
            });
          }}
        />
      ) : (
        <Button
          text="Follow"
          height="small"
          width="small"
          onClick={async () => {
            if (userDoc?.uid) {
              await addFollowers(userDoc?.uid, seller.uid);
              queryClient.invalidateQueries({
                queryKey: ['authUser'],
              });
            } else {
              router.push('/login');
            }
          }}
        />
      )}
    </div>
  );
};
const SellerCardBody = ({
  seller,
}: {
  seller: PublicUserDocument & { products: ProductDocument[] };
}) => {
  if (!seller.products) return <>No products found</>;
  return (
    <Link
      href={`/profile/${seller.username_slug}`}
      className="flex gap-[0.8rem]"
    >
      <div className="relative h-[24rem] w-[24rem] object-contain">
        <SafeImage
          src={seller.products[0]?.thumbnail}
          key={seller.products[0]?.thumbnail}
          alt={seller.products[0]?.title}
          fill
          className="object-cover"
        />
      </div>
      <div className="flex h-full flex-col gap-[0.8rem]">
        {seller.products[1] && (
          <div className="relative h-[11.6rem] w-[10.4rem] object-cover">
            <SafeImage
              src={seller.products[1].thumbnail}
              fill
              alt={seller.products[1].title}
              key={seller.products[1].thumbnail}
            />
          </div>
        )}
        {seller.products[2] && (
          <div className="relative h-[11.6rem] w-[10.4rem] object-cover">
            <SafeImage
              src={seller.products[2].thumbnail}
              fill
              alt={seller.products[2].title}
              key={seller.products[2].thumbnail}
            />
          </div>
        )}
      </div>
    </Link>
  );
};

const SellerCard = ({
  seller,
}: {
  seller: PublicUserDocument & { products: ProductDocument[] };
}) => {
  return (
    <div className="my-[1.6rem] flex w-[38rem] flex-col gap-[1.6rem] rounded-[0.4rem] bg-white p-[1.6rem] shadow-lg">
      <SellerCardHeader seller={seller} />
      <SellerCardBody seller={seller} />
    </div>
  );
};

const SellersToFollow = ({
  sellers,
}: {
  sellers: (PublicUserDocument & { products: ProductDocument[] })[];
}) => {
  return (
    <div className="flex flex-col items-center gap-[4rem]  px-[1.6rem] py-[12rem] lg:px-[6.4rem]">
      <h4 className=" w-min whitespace-nowrap text-center text-[2.4rem] font-semibold">
        Sellers to Follow
      </h4>
      <div className="flex w-full gap-[2.4rem] overflow-x-scroll lg:w-[120rem]">
        {sellers?.map((seller) => (
          <SellerCard key={seller.uid} seller={seller} />
        ))}
      </div>
    </div>
  );
};

export default SellersToFollow;
