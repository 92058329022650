import React, { useRef, useEffect } from 'react';
import { Star } from './SellersToFollow';
import { ChevronLeftIcon, ChevronRightIcon } from '@c/icons';

const ReviewContent = ({ children }: { children: React.ReactElement }) => (
  <div className="flex flex-col gap-[2.4rem]">
    <div className="flex w-full justify-center">
      <Star />
      <Star />
      <Star />
      <Star />
      <Star />
    </div>
    <span className="text-[1.6rem]">{children}</span>
  </div>
);

const ReviewCard = ({ review }: { review: any }) => (
  <div className="flex h-[30rem] w-[40rem] shrink-0 flex-col justify-between gap-[2.4rem] rounded-[2rem] border border-zinc-100 bg-[#FAFAFA] px-[2.4rem] py-[4rem]">
    <ReviewContent>
      <div className="flex h-full flex-col items-center gap-[1.6rem]">
        <span className="line-clamp-4 text-center">{review.content}</span>
        <span className="flex justify-between">
          <div className="flex w-full items-center justify-center gap-[0.8rem]">
            <div className="flex flex-col items-center">
              <h1 className="text-[1.6rem] font-semibold">{review.name}</h1>
              {/* <h3 className="text-[1.4rem] font-light">{review.location}</h3> */}
            </div>
          </div>
        </span>
      </div>
    </ReviewContent>
  </div>
);

const Reviews = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (ref.current) {
        ref.current.scrollLeft += 1000;

        if (
          ref.current.scrollLeft >=
          ref.current.scrollWidth - ref.current.clientWidth
        ) {
          ref.current.scrollLeft = 0;
        }
      }
    }, 60000);

    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <div className="mt-16 flex w-full items-center justify-between pl-8 sm:pl-0 lg:px-default-padding-x">
      <button
        className="hidden lg:flex"
        onClick={() => {
          if (ref.current) {
            ref.current.scrollLeft -= 1000;
          }
        }}
      >
        <ChevronLeftIcon
          className="h-14 w-14 cursor-pointer text-brand-secondary"
          variant="left"
        />
      </button>

      <div
        className="lg:scrollbar-auto flex max-w-default gap-[1.6rem] overflow-x-scroll scroll-smooth scrollbar-none sm:pl-0 lg:overflow-x-hidden"
        ref={ref}
      >
        {STATIC_REVIEWS.map((review) => (
          <ReviewCard review={review} key={review.name} />
        ))}
      </div>

      <button
        className="hidden lg:flex"
        onClick={() => {
          if (ref.current) {
            ref.current.scrollLeft += 1000;
          }
        }}
      >
        <ChevronRightIcon
          className="h-14 w-14 cursor-pointer text-brand-secondary"
          variant="right"
        />
      </button>
    </div>
  );
};

const SeeWhatOurCustomersSay = () => {
  return (
    <div className="mt-[1.6rem] flex w-full flex-col overflow-hidden rounded-brand bg-inherit bg-white px-[1rem] py-[10rem]">
      <div className="mx-auto flex w-full flex-col lg:w-[144rem]">
        <div className="flex w-full justify-center">
          <h3 className="top-[8rem] flex h-[8.4rem] items-center text-center text-[2.4rem] font-semibold lg:text-left lg:text-[3.2rem] ">
            Trusted by 200,000+ customers
          </h3>
        </div>

        <Reviews />
      </div>
    </div>
  );
};

const STATIC_REVIEWS = [
  {
    name: 'Seth Underwood',
    content:
      'I ordered a set of suspension that got lost in the mail and in a matter of a few days they had it figured out and a new kit on the way with no hassle at all. Great business and great people!',
    image: 'sethunderwood.jpg',
    location: 'Miami, USA',
  },
  {
    name: 'Luc Santos',
    content:
      'As the owner of a Motocross vacation company I am constantly needing and selling parts. Being able to buy or sell with a few clicks on MX Locker helps me run my business fast and efficiently, either getting the most money for my product or helping me find the best deals.',
    image: 'lucsantos.png',
    location: 'Miami, USA',
  },
  {
    name: 'Darian Sanayei',
    content:
      'I got introduced to MX Locker right when it started, and I’m so glad I did. Being able to sell products to customers worldwide is a huge advantage. There are also great deals on MX Locker every day!',
    image: 'dariansanayei.jpg',
    location: 'Miami, USA',
  },
  {
    name: 'Fernando Ramos',
    content:
      'The platform works great, and I have sold 100% of my items with 0 issues. If you have gear or parts you no longer use, snap a few pics and post it. Best way to earn some extra cash! ',
    image: 'fernandoramos.png',
    location: 'Miami, USA',
  },
  {
    name: 'Braxton George',
    content:
      'MX Locker is the marketplace motocross needed. Being able to buy and sell lightly used gear at a huge cut or profit is perfect, and something I was looking for with my background in the sneaker resell market. The website is dialed and deals can’t be beat!',
    image: 'braxtongeorge.png',
    location: 'Miami, USA',
  },
  {
    name: 'Chris Byars',
    content:
      'Literally my first go-to sport when I’m looking for some new gear or parts. Always find a solid deal compared to retail prices. Can’t beat it!',
    image: 'chrisbyars.png',
    location: 'Miami, USA',
  },
];

export default SeeWhatOurCustomersSay;
