import {
  collection,
  CollectionReference,
  doc,
  getDoc,
} from '@firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import { db } from '@util/firebase';
import { cleanLocation, getProductsByIds } from '@util/firestore/products';
import { useAuth } from 'context/AuthContext';
import { UserProfile } from 'models/user';

const profileRef = collection(
  db,
  'user_profiles'
) as CollectionReference<UserProfile>;
const useRecentlyViewed = () => {
  const { userDoc } = useAuth();

  const { data: recentlyViewedProducts } = useQuery({
    queryKey: ['recentlyViewedProducts', userDoc?.uid ?? ''],

    queryFn: async () => {
      if (!userDoc?.uid) return [];
      const d = await getDoc(doc(profileRef, userDoc?.uid));
      if (!d.exists()) return [];
      const user_profile = d.data() as UserProfile | undefined;
      if (!user_profile) {
        return [];
      }
      const views =
        user_profile.interactions?.views
          ?.sort((v1, v2) => (v1.date ?? 0) - (v2.date ?? 0))
          .map((p) => p.pid) ?? [];
      if (views.length === 0) {
        return [];
      }
      const products = await getProductsByIds(views.slice(0, 30));
      return products
        .sort((a, b) => {
          const viewed_a = user_profile.interactions?.views?.find(
            (v) => v.pid === a.id
          );
          const viewed_b = user_profile.interactions?.views?.find(
            (v) => v.pid === b.id
          );
          if (!viewed_a || !viewed_b) {
            return 0;
          }
          return (viewed_b.date ?? 0) - (viewed_a.date ?? 0);
        })
        .map((p) => cleanLocation(p));
    },
    throwOnError: true,
    enabled: !!userDoc?.uid,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  return {
    recentlyViewedProducts,
  };
};

export default useRecentlyViewed;
