import React from 'react';

const Highlights = () => {
  return (
    <div className="hidden h-[16rem] w-full bg-black lg:flex">
      <div className="flex w-full justify-evenly sm:mx-auto sm:max-w-content">
        <Frame
          title="Discover"
          description="Thousands of listings, new and used, from riders around the world."
        />
        <Frame
          title="Sell"
          description="Start listing today and earn extra cash on items you no longer use."
        />
        <Frame
          title="Engage"
          description="Be part of our growing community. Built for riders, by riders."
        />
      </div>
    </div>
  );
};

type FrameProps = {
  title: string;
  description: string;
};

const Frame = ({ title, description }: FrameProps) => {
  return (
    <div className="flex w-full flex-col items-center gap-[0.4rem] py-[3.2rem] sm:mx-auto sm:max-w-content">
      <span className="flex items-center text-center text-[2.4rem] font-medium leading-[3.1rem] text-brand-white">
        {title}
      </span>
      <span className="w-[32rem] text-center font-[1.6rem] leading-[2.4rem] text-brand-white">
        {description}
      </span>
    </div>
  );
};

export default Highlights;
