import SafeImage from '@ui/SafeImage';
import { getCDNUrl } from '@util/index';
import { AppStoreButtons } from 'app/get-the-app/components/GetTheApp';
import React from 'react';
const GetTheAppCTA = () => {
  const getMockupImage = () => {
    if (process.env.NEXT_PUBLIC_IS_STAGING) {
      return getCDNUrl(
        'https://mxlocker-staging.b-cdn.net/assets/mobile-mockup-shadow_1920x2560.png'
      );
    }

    return getCDNUrl(
      'https://cdn.mxlocker.com/assets/mobile-mockup-shadow_1920x2560.png'
    );
  };

  return (
    <div className="w-full bg-black">
      <div className="mx-auto flex max-w-[144rem] justify-between overflow-hidden bg-black py-16">
        <div className="flex w-full flex-col items-center justify-center px-[5rem] lg:flex-row lg:justify-start lg:px-default-padding-x">
          <div className="hidden lg:flex">
            <SafeImage
              src={getMockupImage()}
              alt="mockup"
              className="h-full w-auto pl-32"
            />
          </div>

          <div className="flex w-full justify-center pt-16 lg:hidden">
            <SafeImage
              src={getMockupImage()}
              alt="mockup"
              className="h-full w-auto"
            />
          </div>

          <div className="absolute z-10 mt-[8rem] block h-[36rem] w-[50rem] bg-gradient-to-t from-black via-transparent to-transparent lg:hidden" />

          <div className="z-20 lg:pl-[15rem]">
            <div className="flex flex-col items-center text-center">
              <AppStoreButtons />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetTheAppCTA;
